import React from 'react';

import './index.css';
import {GreenSection} from "../../components/greenSection/greenSection";
import {BreadcrumbComponent} from "../../components/breadcrumb/breadcrumb";
import {useTranslation} from "react-i18next";

export function DesignEngineering() {
    const { t } = useTranslation();
    const items = [
        {
            title: (
                <>
                    <span>{t("designAndEngineeringPage.title")}</span>
                </>
            ),
        }
    ];
    return (
        <div className="about-us-container">
            <GreenSection headText={t("designAndEngineeringPage.title")} text="" />
            <BreadcrumbComponent items={items}/>
            <div className="gray-background">
                <div className="content article">
                    <p>{t("designAndEngineeringPage.list.text")}</p>
                </div>
            </div>
        </div>
    )
}
