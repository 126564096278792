import React from 'react';
import {Link} from "react-router-dom";
import {RightCircleOutlined} from "@ant-design/icons";

import {CardComponent} from "../../components/card/card";
import {CarouselComponent} from "../../components/carousel/carousel";
import greenBackground from '../../images/green-background.jpeg';
import mask from '../../images/slider-mask.png';
import img1 from '../../images/img1.png';
import img2 from '../../images/img2.png';
import img3 from '../../images/img3.png';
import img4 from '../../images/img4.png';
import basket from '../../images/basket.png';
import enviromental from '../../images/enviromental.png';
import waste from '../../images/відходи1.png';
import image1 from '../../images/environmental-protection-3341942_1280.jpg';
import image2 from '../../images/istockphoto-457978455-612x612.jpg';
import image3 from '../../images/istockphoto-492946736-612x612.jpg';
import image4 from '../../images/istockphoto-530203194-612x612.jpg';
import image5 from '../../images/istockphoto-871137078-612x612.jpg';
import image6 from '../../images/istockphoto-1271086205-612x612.jpg';
import image7 from '../../images/istockphoto-1365872772-612x612.jpg';
import image8 from '../../images/istockphoto-1438931606-612x612.jpg';
import image9 from '../../images/istockphoto-1491588104-612x612.jpg';
import './index.css';
import {useTranslation} from "react-i18next";

export function Home() {
    const { t } = useTranslation();
    const clickLink = () => {
        const link = document.getElementById("link");
        console.log(link);
        link.click()
    }
    return (
        <div className="home-container">
            <div className="container-fluid container-slider" onClick={clickLink}>
                <div className="slider-img">
                    <img src={greenBackground} className="img-fluid" alt=""/>
                </div>
                <div className="container slider-content">
                    <h1 className="section-title">{t("homePage.ourFeature")}</h1>
                    <Link className="new-class-btn" to="/environmental-services" id="link">{t("homePage.buttonService")} <RightCircleOutlined style={{paddingLeft: '10px'}} /> </Link>
                </div>
                <div className="slider-mask">
                    <img src={mask} className="img-fluid" alt="" />
                </div>
            </div>
            <div className="gray-background">
                <div className="environmental-services service">
                    <h3 className="section-title">{t("homePage.whatServices.title")}</h3>
                    <div className="row row-offer new-row">
                        <CardComponent image={basket} title={t("homePage.whatServices.wasteManagement")} link="/environmental-services/waste-management" />
                        <CardComponent image={enviromental} title={t("homePage.whatServices.environmentalProtection")} link="/environmental-services/environmental-protection" />
                        <CardComponent image={waste} title={t("homePage.whatServices.landfillSite")} link="/environmental-services/landfill-sites" />
                    </div>
                </div>
                <div className="about-ecofox">
                    <div className="col-12 col-md-6 col-left">
                        <h3 className="section-title">{t("homePage.company.title")} <font>Ecofox International</font></h3>
                        <p>{t("homePage.company.text")}</p>
                    </div>
                    <div className="col-12 col-md-6 col-right">
                        <CarouselComponent images={[image1, image2, image3, image4, image5, image6, image7, image8, image9]} />
                    </div>
                </div>
            </div>
            <div className="about-us">
                <div className="col icon-wrapper">
                    <div className="icon-item">
                        <div className="img">
                            <img src={img1} alt="Сучасні технології" />
                            <div className="line"></div>
                        </div>
                        <h3><font><font>{t("homePage.aboutCompanyGreen.first.title")}</font></font></h3>
                        <p><font><font>{t("homePage.aboutCompanyGreen.first.text")}</font></font></p>
                    </div>
                    <div className="icon-item">
                        <div className="img">
                            <img src={img2} alt="Досвідчена команда" />
                            <div className="line"></div>
                        </div>
                        <h3><font><font>{t("homePage.aboutCompanyGreen.second.title")}</font></font></h3>
                        <p><font><font>{t("homePage.aboutCompanyGreen.second.text")}</font></font></p>
                    </div>
                    <div className="icon-item">
                        <div className="img">
                            <img src={img3} alt="Комплексні рішення" />
                            <div className="line"></div>
                        </div>
                        <h3><font><font>{t("homePage.aboutCompanyGreen.three.title")}</font></font></h3>
                        <p><font><font>{t("homePage.aboutCompanyGreen.three.text")}</font></font></p>
                    </div>
                    <div className="icon-item">
                        <div className="img">
                            <img src={img4} alt="Консультації" />
                            <div className="line"></div>
                        </div>
                        <h3><font><font>{t("homePage.aboutCompanyGreen.four.title")}</font></font></h3>
                        <p><font><font>{t("homePage.aboutCompanyGreen.four.text")}</font></font></p>
                    </div>
                </div>
                <div className="col-12">
                    <Link to="/about-us">
                        <RightCircleOutlined />
                    </Link>
                </div>
            </div>
        </div>
    )
}
