import React from 'react';

import {GreenSection} from "../../components/greenSection/greenSection";
import {BreadcrumbComponent} from "../../components/breadcrumb/breadcrumb";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function WasteProcessing() {
    const { t } = useTranslation();
    const items = [
        {
            title: (
                <>
                    <Link to="/environmental-services"><span>{t("environmentalServicePage.title")}</span></Link>
                </>
            ),
        },
        {
            title: (
                <>
                    <Link to="/environmental-services/landfill-sites"><span>{t("landfillSitesPage.title")}</span></Link>
                </>
            ),
        },
        {
            title: (
                <>
                    <span>{t("wasteProcessingPage.title")}</span>
                </>
            ),
        }
    ];
    return (
        <div className="enviromental-service-container">
            <GreenSection headText={t("wasteProcessingPage.title")} text="" />
            <BreadcrumbComponent items={items}/>
            <div className="gray-background">
                <div className="content article">
                    <p>{t("wasteProcessingPage.list.startText")}</p>
                    <ul>
                        <li>
                            <b>{t("wasteProcessingPage.list.oneBold")}</b> {t("wasteProcessingPage.list.one")}
                        </li>
                        <li>
                            <b>{t("wasteProcessingPage.list.twoBold")}</b> {t("wasteProcessingPage.list.two")}
                        </li>
                        <li>
                            <b>{t("wasteProcessingPage.list.threeBold")}</b> {t("wasteProcessingPage.list.three")}
                        </li>
                    </ul>
                    <p>{t("wasteProcessingPage.list.endText")}</p>
                </div>
            </div>
        </div>
    )
}
