import React from 'react';

import './index.css';
import banner from '../../images/green-background.jpeg'

export function GreenSection(props) {
    return (
        <div className="green-section" >
            <div className="slider-img">
                <img src={banner} alt="proGEO" />
            </div>
            <div className="row">
                <h1 className="section-title">{props.headText}</h1>
                <p>{props.text}</p>
            </div>
        </div>
    )
}
