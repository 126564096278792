import React from 'react';

import './index.css';
import {GreenSection} from "../../components/greenSection/greenSection";
import {BreadcrumbComponent} from "../../components/breadcrumb/breadcrumb";
import {CardComponent} from "../../components/card/card";
import basket from "../../images/basket.png";
import enviromental from "../../images/enviromental.png";
import img1 from "../../images/бродіння.png";
import img2 from "../../images/мвр.png";
import img3 from "../../images/геологія.png";
import img4 from "../../images/компостування.png";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function WasteManagement() {
    const { t } = useTranslation();
    const items = [
        {
            title: (
                <>
                    <Link to="/environmental-services"><span>{t("environmentalServicePage.title")}</span></Link>
                </>
            ),
        },
        {
            title: (
                <>
                    <span>{t("wasteManagementPage.title")}</span>
                </>
            ),
        }
    ];
    return (
        <div className="enviromental-service-container">
            <GreenSection headText={t("wasteManagementPage.title")} text="" />
            <BreadcrumbComponent items={items}/>
            <div className="gray-background">
                <div className="environmental-services">
                    <div className="row row-offer">
                        <CardComponent image={basket} title={t("wasteManagementPage.blocks.consulting")} link="/environmental-services/waste-management/technological-consulting" />
                        <CardComponent image={img1} title={t("wasteManagementPage.blocks.concepts")} link="/environmental-services/waste-management/concepts" />
                        <CardComponent image={img2} title={t("wasteManagementPage.blocks.designAndEstimateDocumentation")} link="/environmental-services/waste-management/design-estimate-documentation" />
                        <CardComponent image={img3} title={t("wasteManagementPage.blocks.decisionsAndPermissions")} link="/environmental-services/waste-management/decisions-permissions" />
                        <CardComponent image={enviromental} title={t("wasteManagementPage.blocks.systemManagement")} link="/environmental-services/waste-management/system-management" />
                        <CardComponent image={img4} title={t("wasteManagementPage.blocks.compostCertification")} link="/environmental-services/waste-management/compost-certification" />
                    </div>
                </div>
            </div>
        </div>
    )
}
