import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Menu, MenuProps} from "antd";
import './index.css';
import {useTranslation} from "react-i18next";

export function NavigationComponent() {
    const { t } = useTranslation();
    const items: MenuProps['items'] = [
        {
            label: (<Link to="/environmental-services">{t("header.service")}</Link>),
            key: 'Environmental services',
        },
        {
            label: (<Link to="/design-and-engineering">{t("header.design")}</Link>),
            key: 'Design and engineering',
        },
        {
            label: (<Link to="/technical-supervision">{t("header.supervision")}</Link>),
            key: 'Technical Supervision',
        },
        // {
        //     label: (<Link to="/contracts">{t("header.contracts")}</Link>),
        //     key: 'EPC contracts',
        // },
        {
            label: (<Link to="/about-us">{t("header.aboutUs")}</Link>),
            key: 'About us',
        },
    ];

    const [current, setCurrent] = useState('Environmental services');
    const onClick: MenuProps['onClick'] = (e) => setCurrent(e.key);

    return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
}
