import React, {Suspense} from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import Routes from "./routes";
import {Default} from "./views/default";
import {ScrollToTop} from "./helper/scrolToTop";
import {Spin} from "./components/spin/spin";

function App() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
            <Suspense fallback={<Spin />}>
                <ScrollToTop />
                <Default>
                  <Routes />
                </Default>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
