import React from 'react';

import './index.css';
import {GreenSection} from "../../components/greenSection/greenSection";
import {BreadcrumbComponent} from "../../components/breadcrumb/breadcrumb";
import {useTranslation} from "react-i18next";

export function AboutUs() {
    const { t } = useTranslation();
    const items = [
        {
            title: (
                <>
                    <span>{t("aboutUsPage.title")}</span>
                </>
            ),
        }
    ];
    return (
        <div className="about-us-container">
            <GreenSection headText={t("aboutUsPage.title")} text="" />
            <BreadcrumbComponent items={items}/>
            <div className="about-us-text">
                <p><b>{t("aboutUsPage.company")}</b> {t("aboutUsPage.startText")}</p>
                <p><b>{t("aboutUsPage.strengths")}</b></p>
                <ul>
                    <li><b>{t("aboutUsPage.strengthsList.oneBold")}</b>{t("aboutUsPage.strengthsList.one")}</li>
                    <li><b>{t("aboutUsPage.strengthsList.twoBold")}</b>{t("aboutUsPage.strengthsList.two")}</li>
                    <li><b>{t("aboutUsPage.strengthsList.threeBold")}</b>{t("aboutUsPage.strengthsList.three")}</li>
                    <li><b>{t("aboutUsPage.strengthsList.fourBold")}</b>{t("aboutUsPage.strengthsList.four")}</li>
                </ul>
                <p><b>{t("aboutUsPage.services")}</b></p>
                <ul>
                    <li><b>{t("aboutUsPage.servicesList.oneBold")}</b>{t("aboutUsPage.servicesList.one")}</li>
                    <li><b>{t("aboutUsPage.servicesList.twoBold")}</b>{t("aboutUsPage.servicesList.two")}</li>
                    <li><b>{t("aboutUsPage.servicesList.threeBold")}</b>{t("aboutUsPage.servicesList.three")}</li>
                    <li><b>{t("aboutUsPage.servicesList.fourBold")}</b>{t("aboutUsPage.servicesList.four")}</li>
                    <li><b>{t("aboutUsPage.servicesList.fiveBold")}</b>{t("aboutUsPage.servicesList.five")}</li>
                </ul>
                <p><b>{t("aboutUsPage.chooseUs")}</b></p>
                <ul>
                    <li><b>{t("aboutUsPage.chooseUsList.oneBold")}</b>{t("aboutUsPage.chooseUsList.one")}</li>
                    <li><b>{t("aboutUsPage.chooseUsList.twoBold")}</b>{t("aboutUsPage.chooseUsList.two")}</li>
                    <li><b>{t("aboutUsPage.chooseUsList.threeBold")}</b>{t("aboutUsPage.chooseUsList.three")}</li>
                </ul>
                <p><b>{t("aboutUsPage.company")}</b> {t("aboutUsPage.endText")}</p>
            </div>
        </div>
    )
}
