import React from 'react';
import {Space} from "antd";
import './index.css';

export function Spin() {
    return <Space direction="vertical" style={{ width: '100%' }}>
        <Space>
            <Spin tip="Loading..." size="large">
                <div className="content" />
            </Spin>
        </Space>
    </Space>
}
