import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import './index.css';

export function BreadcrumbComponent(props) {
    const defaultItems = [{
        title: (
            <>
                <Link to="/">
                    <HomeOutlined />
                </Link>
            </>
        ),
    }];
    const items = defaultItems.concat(props.items);
    return <Breadcrumb items={items} className="breadcrumb"/>;
}
