import React from 'react';

import './index.css';
import {GreenSection} from "../../components/greenSection/greenSection";
import {BreadcrumbComponent} from "../../components/breadcrumb/breadcrumb";
import {CardComponent} from "../../components/card/card";
import img1 from "../../images/бродіння.png";
import img2 from "../../images/компостування.png";
import img3 from "../../images/мвр.png";
import img4 from "../../images/сортування.png";
import {useTranslation} from "react-i18next";

export function TechnicalSupervision() {
    const { t } = useTranslation();
    const items = [
        {
            title: (
                <>
                    <span>{t("supervisionPage.title")}</span>
                </>
            ),
        }
    ];
    return (
        <div className="technical-supervision-container">
            <GreenSection headText={t("supervisionPage.title")} text="" />
            <BreadcrumbComponent items={items}/>
            <div className="gray-background">
                <div className="content article">
                    <p>{t("supervisionPage.list.startText")}</p>
                    <ul>
                        <li>
                            <b>{t("supervisionPage.list.oneBold")}</b> {t("supervisionPage.list.one")}
                        </li>
                        <li>
                            <b>{t("supervisionPage.list.twoBold")}</b> {t("supervisionPage.list.two")}
                        </li>
                        <li>
                            <b>{t("supervisionPage.list.threeBold")}</b> {t("supervisionPage.list.three")}
                        </li>
                        <li>
                            <b>{t("supervisionPage.list.fourBold")}</b> {t("supervisionPage.list.four")}
                        </li>
                        <li>
                            <b>{t("supervisionPage.list.fiveBold")}</b> {t("supervisionPage.list.five")}
                        </li>
                        <li>
                            <b>{t("supervisionPage.list.sixBold")}</b> {t("supervisionPage.list.six")}
                        </li>
                    </ul>
                    <p>{t("supervisionPage.list.endText")}</p>
                </div>
                <div className="environmental-services">
                    <div className="row row-offer">
                        <CardComponent image={img1} title={t("supervisionPage.blocks.consulting")}
                                       link="/technical-supervision/consulting"/>
                        <CardComponent image={img2} title={t("supervisionPage.blocks.procurementTenderSupport")}
                                       link="/technical-supervision/procurement-tender-support"/>
                        <CardComponent image={img3} title={t("supervisionPage.blocks.projectWorks")}
                                       link="/technical-supervision/project-works"/>
                        <CardComponent image={img4} title={t("supervisionPage.blocks.environmentalConsulting")}
                                       link="/technical-supervision/environmental-consulting"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
