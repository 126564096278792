import React from 'react';
import { Carousel } from 'antd';
import './index.css';

export function CarouselComponent(props) {
    return (
        <Carousel autoplay>
            {props.images.map((src, i) => <div className="carousel" key={i}><img src={src} alt={i} /></div>)}
        </Carousel>
    )
}
