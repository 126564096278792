import React from 'react';

import {GreenSection} from "../../components/greenSection/greenSection";
import {BreadcrumbComponent} from "../../components/breadcrumb/breadcrumb";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function TechnologicalConsulting() {
    const { t } = useTranslation();
    const items = [
        {
            title: (
                <>
                    <Link to="/environmental-services"><span>{t("environmentalServicePage.title")}</span></Link>
                </>
            ),
        },
        {
            title: (
                <>
                    <Link to="/environmental-services/waste-management"><span>{t("wasteManagementPage.title")}</span></Link>
                </>
            ),
        },
        {
            title: (
                <>
                    <span>{t("technologicalConsultingPage.title")}</span>
                </>
            ),
        }
    ];
    return (
        <div className="enviromental-service-container">
            <GreenSection headText={t("technologicalConsultingPage.title")} text="" />
            <BreadcrumbComponent items={items}/>
            <div className="gray-background">
                <div className="content article">
                    <p>{t("technologicalConsultingPage.startText")}</p>
                    <p>{t("technologicalConsultingPage.proposal")}</p>
                    <ul>
                        <li>{t("technologicalConsultingPage.list.one")}</li>
                        <li>{t("technologicalConsultingPage.list.two")}</li>
                        <li>{t("technologicalConsultingPage.list.three")}</li>
                        <li>{t("technologicalConsultingPage.list.four")}</li>
                        <li>{t("technologicalConsultingPage.list.five")}</li>
                        <li>{t("technologicalConsultingPage.list.six")}</li>
                        <li>{t("technologicalConsultingPage.list.seven")}</li>
                        <li>{t("technologicalConsultingPage.list.eight")}</li>
                        <li>{t("technologicalConsultingPage.list.nine")}</li>
                    </ul>
                    <p>{t("technologicalConsultingPage.endText")}</p>
                </div>
            </div>
        </div>
    )
}
