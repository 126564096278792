import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Layout, Space} from "antd";
import {Content, Header, Footer} from "antd/es/layout/layout";
import {NavigationComponent} from "../../components/navigation";

import './index.css';
import logo from '../../images/ecofox-20.png'
import logoGreen from '../../images/ecofox-18.png'
import {PhoneOutlined, IeOutlined, CompassOutlined} from "@ant-design/icons";
import {LanguageSelector} from "../../components/languageSelector/languageSelector";

export function Default(props) {
    const { t } = useTranslation();
    const [header, setHeader] = useState("header")

    const listenScrollEvent = () => {
        if (window.scrollY < 73) {
            return setHeader("header")
        } else if (window.scrollY > 70) {
            return setHeader("header sps--blw")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
                <Layout>
                    <Header className={header}>
                       <div className="logo-header">
                           <Link to="/">
                               <img src={logo} alt={''} className="logo" />
                           </Link>
                       </div>
                        <div className="contact-header">
                            <PhoneOutlined />
                            <Link to="tel:+380503828536">
                                <font style= {{verticalAlign: 'inherit'}}>
                                    <font style= {{verticalAlign: 'inherit'}}>+38 (050) 382 85 36</font>
                                </font>
                            </Link>
                        </div>
                        <LanguageSelector />
                        <NavigationComponent />
                    </Header>
                    <Content className={'content'}>{props.children}</Content>
                    <Footer className={'footer'}>
                        <div className="container-fluid container-footer">
                            <div className="row wrapper">
                                <div className="col-6 col-lg-3 item-logo">
                                    <Link to="/">
                                        <img src={logoGreen} alt="ecofox"  className="logo-footer"/>
                                    </Link>
                                </div>
                                <div className="col-6 col-lg-3 item service">
                                    <h4>
                                        <font style= {{verticalAlign: 'inherit'}}>
                                            <font style={{verticalAlign: 'inherit'}}>{t("header.service")}</font>
                                        </font>
                                    </h4>
                                    <ul className="list-unstyled">
                                        <li>
                                            <Link to="/environmental-services/waste-management">
                                                <font style= {{verticalAlign: 'inherit'}}>
                                                    <font style= {{verticalAlign: 'inherit'}}>{t("footer.wasteManagement")}</font>
                                                </font>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/environmental-services/environmental-protection">
                                                <font style= {{verticalAlign: 'inherit'}}>
                                                    <font style= {{verticalAlign: 'inherit'}}>{t("footer.environmentalProtection")}</font>
                                                </font>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/environmental-services/landfill-sites">
                                                <font style= {{verticalAlign: 'inherit'}}>
                                                    <font style= {{verticalAlign: 'inherit'}}>{t("footer.landfillSite")}</font>
                                                </font>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 col-lg-3 item">
                                    <h4>
                                        <font style= {{verticalAlign: 'inherit'}}>
                                            <font style= {{verticalAlign: 'inherit'}}>{t("footer.menu")}</font>
                                        </font>
                                    </h4>
                                    <ul className="list-unstyled">
                                        <li>
                                            <Link to="/environmental-services">
                                                <font style= {{verticalAlign: 'inherit'}}>
                                                    <font style= {{verticalAlign: 'inherit'}}>{t("header.service")}</font>
                                                </font>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/design-and-engineering">
                                                <font style= {{verticalAlign: 'inherit'}}>
                                                    <font style= {{verticalAlign: 'inherit'}}>{t("header.design")}</font>
                                                </font>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/technical-supervision">
                                                <font style= {{verticalAlign: 'inherit'}}>
                                                    <font style= {{verticalAlign: 'inherit'}}>{t("header.supervision")}</font>
                                                </font>
                                            </Link>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <Link to="/contracts">*/}
                                        {/*        <font style= {{verticalAlign: 'inherit'}}>*/}
                                        {/*            <font style= {{verticalAlign: 'inherit'}}>{t("header.contracts")}</font>*/}
                                        {/*        </font>*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                        <li>
                                            <Link to="/about-us">
                                                <font style= {{verticalAlign: 'inherit'}}>
                                                    <font style= {{verticalAlign: 'inherit'}}>{t("header.aboutUs")}</font>
                                                </font>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 col-lg-3 item">
                                    <h4>
                                        <font style= {{verticalAlign: 'inherit'}}>
                                            <font style= {{verticalAlign: 'inherit'}}>{t("footer.contacts.title")}</font>
                                        </font>
                                    </h4>
                                    <ul className="list-unstyled">
                                        <li className="list-contact"><PhoneOutlined />
                                            <Link to="tel:+380503828536">
                                                <font style= {{verticalAlign: 'inherit'}}>
                                                    <font style= {{verticalAlign: 'inherit'}}>+38 (050) 382 85 36</font>
                                                </font>
                                            </Link>
                                        </li>
                                        <li className="list-contact">
                                            <IeOutlined />
                                            <Link to="mailto:ecofox.office@gmail.com">ecofox.office@gmail.com</Link>
                                        </li>
                                        <li className="list-contact">
                                            <CompassOutlined />
                                            <Link to="/about-us">{t("footer.contacts.address")}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Footer>
                </Layout>
            </Space>
        </>
    )
}
