import React from 'react';

import './index.css';
import {GreenSection} from "../../components/greenSection/greenSection";
import {BreadcrumbComponent} from "../../components/breadcrumb/breadcrumb";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function EnvironmentalConsulting() {
    const { t } = useTranslation();
    const items = [
        {
            title: (
                <>
                    <Link to="/technical-supervision"><span>{t("supervisionPage.title")}</span></Link>
                </>
            ),
        },
        {
            title: (
                <>
                    <span>{t("environmentalConsultingPage.title")}</span>
                </>
            ),
        }
    ];
    return (
        <div className="enviromental-service-container">
            <GreenSection headText={t("environmentalConsultingPage.title")} text="" />
            <BreadcrumbComponent items={items}/>
            <div className="gray-background">
                <div className="content article">
                    <p>{t("environmentalConsultingPage.list.startText")}</p>
                    <ul>
                        <li><b>{t("environmentalConsultingPage.list.oneBold")}</b> {t("environmentalConsultingPage.list.one")}</li>
                        <li><b>{t("environmentalConsultingPage.list.twoBold")}</b> {t("environmentalConsultingPage.list.two")}</li>
                        <li><b>{t("environmentalConsultingPage.list.threeBold")}</b> {t("environmentalConsultingPage.list.three")}</li>
                        <li><b>{t("environmentalConsultingPage.list.fourBold")}</b> {t("environmentalConsultingPage.list.four")}</li>
                        <li><b>{t("environmentalConsultingPage.list.fiveBold")}</b> {t("environmentalConsultingPage.list.five")}</li>
                        <li><b>{t("environmentalConsultingPage.list.sixBold")}</b> {t("environmentalConsultingPage.list.six")}</li>
                    </ul>
                    <p>{t("environmentalConsultingPage.list.endText")}</p>
                </div>
            </div>
        </div>
    )
}
