import React from 'react';

import './index.css';
import {GreenSection} from "../../components/greenSection/greenSection";
import {BreadcrumbComponent} from "../../components/breadcrumb/breadcrumb";
import {CardComponent} from "../../components/card/card";
import basket from "../../images/мвр.png";
import enviromental from "../../images/сортування.png";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function LandfillSite() {
    const { t } = useTranslation();
    const items = [
        {
            title: (
                <>
                    <Link to="/environmental-services"><span>{t("environmentalServicePage.title")}</span></Link>
                </>
            ),
        },
        {
            title: (
                <>
                    <span>{t("landfillSitesPage.title")}</span>
                </>
            ),
        }
    ];
    return (
        <div className="enviromental-service-container">
            <GreenSection headText={t("landfillSitesPage.title")} text="" />
            <BreadcrumbComponent items={items}/>
            <div className="gray-background">
                <div className="environmental-services">
                    <div className="row row-offer">
                        <CardComponent image={basket} title={t("landfillSitesPage.blocks.landfill")} link="/environmental-services/landfill-sites/landfill" />
                        <CardComponent image={enviromental} title={t("landfillSitesPage.blocks.wasteProcessing")} link="/environmental-services/landfill-sites/waste-processing" />
                    </div>
                </div>
            </div>
        </div>
    )
}
