import React from 'react';
import { Card } from 'antd';

import './index.css';
import {Link} from "react-router-dom";
import {RightCircleOutlined} from "@ant-design/icons";

export function CardComponent(props) {
    return (
        <Card>
            <Link to={props.link}>
                <div className="icon">
                    <img src={props.image} alt="" />
                </div>
                <h4>{props.title}</h4>
                <RightCircleOutlined />
            </Link>
        </Card>
    )
}
