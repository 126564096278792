import React from 'react';
import { Routes, Route } from 'react-router-dom';

import {Home} from "../views/home";
import {AboutUs} from "../views/aboutUs";
import {EnvironmentalServices} from "../views/environmentalServices";
import {DesignEngineering} from "../views/designEngineering";
import {TechnicalSupervision} from "../views/technicalSupervision";
import {WasteManagement} from "../views/wasteManagement";
import {EnvironmentalProtection} from "../views/environmentalProtection";
import {Consulting} from "../views/consulting";
import {ProcurementTenderSupport} from "../views/procurementTenderSupport";
import {ProjectWorks} from "../views/projectWorks";
import {EnvironmentalConsulting} from "../views/environmentalConsulting";
import {EnvironmentalImpactAssessment} from "../views/environmentalImpactAssessment";
import {AnalyzesEnvironment} from "../views/analyzesEnvironment";
import {EnvironmentalReporting} from "../views/environmentalReporting";
import {EnvironmentalProtectionPrograms} from "../views/environmentalProtectionPrograms";
import {TechnologicalConsulting} from "../views/technologicalConsulting";
import {Concepts} from "../views/concepts";
import {DesignEstimateDocumentation} from "../views/designEstimateDocumentation";
import {DecisionsPermissions} from "../views/decisionsPermissions";
import {SystemManagement} from "../views/systemManagement";
import {CompostCertification} from "../views/compostCertification";
import {LandfillSite} from "../views/landfillSite";
import {Landfill} from "../views/landfill";
import {WasteProcessing} from "../views/wasteProcessing";

export default function Navigation() {
    return (
        <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route exact path='/about-us' element={<AboutUs/>} />
            <Route exact path='/environmental-services' element={<EnvironmentalServices/>} />
            <Route path='/environmental-services/waste-management' element={<WasteManagement/>} />
            <Route path='/environmental-services/waste-management/technological-consulting' element={<TechnologicalConsulting/>} />
            <Route path='/environmental-services/waste-management/concepts' element={<Concepts/>} />
            <Route path='/environmental-services/waste-management/design-estimate-documentation' element={<DesignEstimateDocumentation/>} />
            <Route path='/environmental-services/waste-management/decisions-permissions' element={<DecisionsPermissions/>} />
            <Route path='/environmental-services/waste-management/system-management' element={<SystemManagement/>} />
            <Route path='/environmental-services/waste-management/compost-certification' element={<CompostCertification/>} />
            <Route path='/environmental-services/environmental-protection' element={<EnvironmentalProtection/>} />
            <Route path='/environmental-services/environmental-protection/environmental-impact-assessment' element={<EnvironmentalImpactAssessment/>} />
            <Route path='/environmental-services/environmental-protection/analyzes-environment' element={<AnalyzesEnvironment/>} />
            <Route path='/environmental-services/environmental-protection/environmental-reporting' element={<EnvironmentalReporting/>} />
            <Route path='/environmental-services/environmental-protection/environmental-protection-programs' element={<EnvironmentalProtectionPrograms/>} />
            <Route path='/environmental-services/landfill-sites' element={<LandfillSite/>} />
            <Route path='/environmental-services/landfill-sites/landfill' element={<Landfill/>} />
            <Route path='/environmental-services/landfill-sites/waste-processing' element={<WasteProcessing/>} />
            <Route exact path='/design-and-engineering' element={<DesignEngineering/>} />
            <Route exact path='/technical-supervision' element={<TechnicalSupervision/>} />
            <Route path='/technical-supervision/consulting' element={<Consulting/>} />
            <Route path='/technical-supervision/procurement-tender-support' element={<ProcurementTenderSupport/>} />
            <Route path='/technical-supervision/project-works' element={<ProjectWorks/>} />
            <Route path='/technical-supervision/environmental-consulting' element={<EnvironmentalConsulting/>} />
            {/*<Route exact path='/contracts' element={<Contracts/>} />*/}
        </Routes>
    )
}
