import React, {useState} from 'react';
import {Select, Space} from "antd";
import './index.css';
import i18n from "../../helper/translation/i18n";

export function LanguageSelector() {
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.
    const chooseLanguage = async (value: string) => {
        await i18n.changeLanguage(value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(value);
    }

    return <Space wrap className="dropdown">
        <Select
            defaultValue={selectedLanguage}
            onChange={chooseLanguage}
            options={[
                { value: 'en', label: 'EN' },
                { value: 'uk', label: 'UA' },
            ]}
        />
    </Space>
}
